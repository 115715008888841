.navbar.navbar-default {
  margin: 0;
  border: 0;
  border-radius: 0;
  text-transform: uppercase;
  background: $white;
  border-bottom: 2px solid #eee;
}

@media screen and (min-width:768px) {
  .navbar.navbar-default {
    padding-left:24px;
    padding-right:24px;
  }
}

.navbar-default ul.nav.navbar-nav {
  padding: 0;
}

.navbar-default .navbar-nav li {
  text-align: left;
}

.navbar-default .navbar-nav>li>a,
.navbar-brand {
  height: 100px;
  line-height: 100px;
  padding-top: 0;
  letter-spacing: 1px;
}

.navbar-default .navbar-nav>li>a,
.dropdown-menu>li>a {
  font-family: $headings;
  font-size: 13pt;
  transition: all .1s;
  font-weight: 700;
  color: $purple;
}

.navbar-default .navbar-nav>li>a.active {
  color: $black;
}

.navbar-default .navbar-nav>li>a:hover {
  color: $black;
}

.navbar-default .navbar-brand {
  margin: 0;
  position: relative;
}

.navbar-default .navbar-collapse {
  border-top: none;
  padding: 0;
}

.navbar-brand .navbar-brand-headline {
  color: $purple;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15pt;
  margin-left: 60px;
  transition: all .5s;

  &:hover {
    color: $black;
  }
}

@media screen and (max-width:994px) {
  .navbar-brand .navbar-brand-headline {
    font-size: 12pt;
    margin-left: 60px;
  }
}

.navbar-default a img {
  float: left;
  width: 50px;
  height: 50px;
  top: 25px;
  position: absolute;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
  color: $black;
  background-color: transparent;
}


.dropdown-menu {
  font-family: $headings;
}


/* collapse button */
.navbar-toggle {
  padding: 10px;
  background-color: $white;
}

.navbar-default .navbar-toggle:hover {
  background: $white;
}

.navbar-default .navbar-toggle:visited,
.navbar-default .navbar-toggle:active {
  background-color: $white;
}


.dropdown-menu>li {
  border-radius: 0;
}

ul.dropdown-menu {
  padding: 0;
  width: 200px;
  border-radius: 0;
  overflow: hidden;
}

.dropdown-menu>li>a {
  font-family: $headings;
  transition: all .5s;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 13pt;
  color: $purple;
  white-space: nowrap;
  height: 50px;
  line-height: 50px;
}

.navbar-nav .open .dropdown-menu>li>a {
  line-height: 50px;
  color: $purple;
}

.navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
  background: #eee;
}