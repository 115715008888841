.example-reports {


  .row {
    padding-bottom:3em;
  }

  h1 {
    line-height:30px;
  }


  .part {
    margin-bottom:1em;
  }

  span.strong {
    font-weight:bold;
    margin-right:1em;
  }


  .pdf-link-report {
    text-decoration:underline;

    a {
      margin-bottom:10px;
    }
  }

  .frameWrap {
    overflow:hidden;
    max-height:530px;
    margin-top:15px;
    margin-bottom:40px;
  }

  .warning span {
    color:red;
  }

 }
