/* section containing tables to do with internal and external snagging */

.internal-external {
  background: #f5f5f5;
  padding: 4em 1em !important;
  margin-bottom: 2em;


  ul {
    font-size: 10pt;
  }

  li {
    text-align: center;
  }

  li:nth-child(odd):not(:first-child) {
    background: #f6f6f6;
  }

  .list-group-item-heading {
    margin-bottom: 0;
    border-radius: 0;
    font-family: $headings;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 13pt;
  }

  h4#internal-external-heading {
    font-weight: 700;
  }

  .example-report {
    background-image: url('../img/making-report.jpg');
    background-position: center;
    background-position: cover;
    width: 100%;
    height: 254px;
    border-radius: 5px;
    border-radius: 3px;
    border: 1px solid rgb(221, 221, 221);
    ;
    margin-top: 3px;

    a.example-report-link {
      display: block;
      background: $white;
      text-transform: uppercase;
      font-family: $headings;
      width: 100%;
      padding: .7em;
      text-align: center;
      font-size: 13pt;
      letter-spacing: 1px;
      font-weight: bold;
      color: rgb(51, 51, 51);
    }
  }
}

/* end of internal external */