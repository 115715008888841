* {
    /* border:1px solid red; */
}

.m0 {
    margin-top: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
}

.mt0 {
    margin-top: 0px !important;
}

.mr0 {
    margin-right: 0px !important;
}

.mb0 {
    margin-bottom: 0px !important;
}

.ml0 {
    margin-left: 0px !important;
}

.p0 {
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
}

.pt0 {
    padding-top: 0px !important;
}

.pr0 {
    padding-right: 0px !important;
}

.pb0 {
    padding-bottom: 0px !important;
}

.pl0 {
    padding-left: 0px !important;
}

.m1 {
    margin-top: 6px !important;
    margin-right: 6px !important;
    margin-bottom: 6px !important;
    margin-left: 6px !important;
}

.mt1 {
    margin-top: 6px !important;
}

.mt-1 {
    margin-top: -6px !important;
}

.mr1 {
    margin-right: 6px !important;
}

.mr-1 {
    margin-right: -6px !important;
}

.mb1 {
    margin-bottom: 6px !important;
}

.mb-1 {
    margin-bottom: -6px !important;
}

.ml1 {
    margin-left: 6px !important;
}

.ml-1 {
    margin-left: -6px !important;
}

.p1 {
    padding-top: 6px !important;
    padding-right: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 6px !important;
}

.pt1 {
    padding-top: 6px !important;
}

.pt-1 {
    padding-top: -6px !important;
}

.pr1 {
    padding-right: 6px !important;
}

.pr-1 {
    padding-right: -6px !important;
}

.pb1 {
    padding-bottom: 6px !important;
}

.pb-1 {
    padding-bottom: -6px !important;
}

.pl1 {
    padding-left: 6px !important;
}

.pl-1 {
    padding-left: -6px !important;
}

.mh1 {
    min-height: 58px !important;
}

.xh1 {
    max-height: 58px !important;
    height: 58px !important;
    overflow: hidden;
}

.m2 {
    margin-top: 12px !important;
    margin-right: 12px !important;
    margin-bottom: 12px !important;
    margin-left: 12px !important;
}

.mt2 {
    margin-top: 12px !important;
}

.mt-2 {
    margin-top: -12px !important;
}

.mr2 {
    margin-right: 12px !important;
}

.mr-2 {
    margin-right: -12px !important;
}

.mb2 {
    margin-bottom: 12px !important;
}

.mb-2 {
    margin-bottom: -12px !important;
}

.ml2 {
    margin-left: 12px !important;
}

.ml-2 {
    margin-left: -12px !important;
}

.p2 {
    padding-top: 12px !important;
    padding-right: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 12px !important;
}

.pt2 {
    padding-top: 12px !important;
}

.pt-2 {
    padding-top: -12px !important;
}

.pr2 {
    padding-right: 12px !important;
}

.pr-2 {
    padding-right: -12px !important;
}

.pb2 {
    padding-bottom: 12px !important;
}

.pb-2 {
    padding-bottom: -12px !important;
}

.pl2 {
    padding-left: 12px !important;
}

.pl-2 {
    padding-left: -12px !important;
}

.mh2 {
    min-height: 73px !important;
}

.xh2 {
    max-height: 73px !important;
    height: 73px !important;
    overflow: hidden;
}

.m3 {
    margin-top: 18px !important;
    margin-right: 18px !important;
    margin-bottom: 18px !important;
    margin-left: 18px !important;
}

.mt3 {
    margin-top: 18px !important;
}

.mt-3 {
    margin-top: -18px !important;
}

.mr3 {
    margin-right: 18px !important;
}

.mr-3 {
    margin-right: -18px !important;
}

.mb3 {
    margin-bottom: 18px !important;
}

.mb-3 {
    margin-bottom: -18px !important;
}

.ml3 {
    margin-left: 18px !important;
}

.ml-3 {
    margin-left: -18px !important;
}

.p3 {
    padding-top: 18px !important;
    padding-right: 18px !important;
    padding-bottom: 18px !important;
    padding-left: 18px !important;
}

.pt3 {
    padding-top: 18px !important;
}

.pt-3 {
    padding-top: -18px !important;
}

.pr3 {
    padding-right: 18px !important;
}

.pr-3 {
    padding-right: -18px !important;
}

.pb3 {
    padding-bottom: 18px !important;
}

.pb-3 {
    padding-bottom: -18px !important;
}

.pl3 {
    padding-left: 18px !important;
}

.pl-3 {
    padding-left: -18px !important;
}

.mh3 {
    min-height: 88px !important;
}

.xh3 {
    max-height: 88px !important;
    height: 88px !important;
    overflow: hidden;
}

.m4 {
    margin-top: 24px !important;
    margin-right: 24px !important;
    margin-bottom: 24px !important;
    margin-left: 24px !important;
}

.mt4 {
    margin-top: 24px !important;
}

.mt-4 {
    margin-top: -24px !important;
}

.mr4 {
    margin-right: 24px !important;
}

.mr-4 {
    margin-right: -24px !important;
}

.mb4 {
    margin-bottom: 24px !important;
}

.mb-4 {
    margin-bottom: -24px !important;
}

.ml4 {
    margin-left: 24px !important;
}

.ml-4 {
    margin-left: -24px !important;
}

.p4 {
    padding-top: 24px !important;
    padding-right: 24px !important;
    padding-bottom: 24px !important;
    padding-left: 24px !important;
}

.pt4 {
    padding-top: 24px !important;
}

.pt-4 {
    padding-top: -24px !important;
}

.pr4 {
    padding-right: 24px !important;
}

.pr-4 {
    padding-right: -24px !important;
}

.pb4 {
    padding-bottom: 24px !important;
}

.pb-4 {
    padding-bottom: -24px !important;
}

.pl4 {
    padding-left: 24px !important;
}

.pl-4 {
    padding-left: -24px !important;
}

.mh4 {
    min-height: 103px !important;
}

.xh4 {
    max-height: 103px !important;
    height: 103px !important;
    overflow: hidden;
}

.m5 {
    margin-top: 30px !important;
    margin-right: 30px !important;
    margin-bottom: 30px !important;
    margin-left: 30px !important;
}

.mt5 {
    margin-top: 30px !important;
}

.mt-5 {
    margin-top: -30px !important;
}

.mr5 {
    margin-right: 30px !important;
}

.mr-5 {
    margin-right: -30px !important;
}

.mb5 {
    margin-bottom: 30px !important;
}

.mb-5 {
    margin-bottom: -30px !important;
}

.ml5 {
    margin-left: 30px !important;
}

.ml-5 {
    margin-left: -30px !important;
}

.p5 {
    padding-top: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 30px !important;
    padding-left: 30px !important;
}

.pt5 {
    padding-top: 30px !important;
}

.pt-5 {
    padding-top: -30px !important;
}

.pr5 {
    padding-right: 30px !important;
}

.pr-5 {
    padding-right: -30px !important;
}

.pb5 {
    padding-bottom: 30px !important;
}

.pb-5 {
    padding-bottom: -30px !important;
}

.pl5 {
    padding-left: 30px !important;
}

.pl-5 {
    padding-left: -30px !important;
}

.mh5 {
    min-height: 118px !important;
}

.xh5 {
    max-height: 118px !important;
    height: 118px !important;
    overflow: hidden;
}

.m6 {
    margin-top: 36px !important;
    margin-right: 36px !important;
    margin-bottom: 36px !important;
    margin-left: 36px !important;
}

.mt6 {
    margin-top: 36px !important;
}

.mt-6 {
    margin-top: -36px !important;
}

.mr6 {
    margin-right: 36px !important;
}

.mr-6 {
    margin-right: -36px !important;
}

.mb6 {
    margin-bottom: 36px !important;
}

.mb-6 {
    margin-bottom: -36px !important;
}

.ml6 {
    margin-left: 36px !important;
}

.ml-6 {
    margin-left: -36px !important;
}

.p6 {
    padding-top: 36px !important;
    padding-right: 36px !important;
    padding-bottom: 36px !important;
    padding-left: 36px !important;
}

.pt6 {
    padding-top: 36px !important;
}

.pt-6 {
    padding-top: -36px !important;
}

.pr6 {
    padding-right: 36px !important;
}

.pr-6 {
    padding-right: -36px !important;
}

.pb6 {
    padding-bottom: 36px !important;
}

.pb-6 {
    padding-bottom: -36px !important;
}

.pl6 {
    padding-left: 36px !important;
}

.pl-6 {
    padding-left: -36px !important;
}

.mh6 {
    min-height: 133px !important;
}

.xh6 {
    max-height: 133px !important;
    height: 133px !important;
    overflow: hidden;
}

.m7 {
    margin-top: 42px !important;
    margin-right: 42px !important;
    margin-bottom: 42px !important;
    margin-left: 42px !important;
}

.mt7 {
    margin-top: 42px !important;
}

.mt-7 {
    margin-top: -42px !important;
}

.mr7 {
    margin-right: 42px !important;
}

.mr-7 {
    margin-right: -42px !important;
}

.mb7 {
    margin-bottom: 42px !important;
}

.mb-7 {
    margin-bottom: -42px !important;
}

.ml7 {
    margin-left: 42px !important;
}

.ml-7 {
    margin-left: -42px !important;
}

.p7 {
    padding-top: 42px !important;
    padding-right: 42px !important;
    padding-bottom: 42px !important;
    padding-left: 42px !important;
}

.pt7 {
    padding-top: 42px !important;
}

.pt-7 {
    padding-top: -42px !important;
}

.pr7 {
    padding-right: 42px !important;
}

.pr-7 {
    padding-right: -42px !important;
}

.pb7 {
    padding-bottom: 42px !important;
}

.pb-7 {
    padding-bottom: -42px !important;
}

.pl7 {
    padding-left: 42px !important;
}

.pl-7 {
    padding-left: -42px !important;
}

.mh7 {
    min-height: 148px !important;
}

.xh7 {
    max-height: 148px !important;
    height: 148px !important;
    overflow: hidden;
}

.m8 {
    margin-top: 48px !important;
    margin-right: 48px !important;
    margin-bottom: 48px !important;
    margin-left: 48px !important;
}

.mt8 {
    margin-top: 48px !important;
}

.mt-8 {
    margin-top: -48px !important;
}

.mr8 {
    margin-right: 48px !important;
}

.mr-8 {
    margin-right: -48px !important;
}

.mb8 {
    margin-bottom: 48px !important;
}

.mb-8 {
    margin-bottom: -48px !important;
}

.ml8 {
    margin-left: 48px !important;
}

.ml-8 {
    margin-left: -48px !important;
}

.p8 {
    padding-top: 48px !important;
    padding-right: 48px !important;
    padding-bottom: 48px !important;
    padding-left: 48px !important;
}

.pt8 {
    padding-top: 48px !important;
}

.pt-8 {
    padding-top: -48px !important;
}

.pr8 {
    padding-right: 48px !important;
}

.pr-8 {
    padding-right: -48px !important;
}

.pb8 {
    padding-bottom: 48px !important;
}

.pb-8 {
    padding-bottom: -48px !important;
}

.pl8 {
    padding-left: 48px !important;
}

.pl-8 {
    padding-left: -48px !important;
}

.mh8 {
    min-height: 163px !important;
}

.xh8 {
    max-height: 163px !important;
    height: 163px !important;
    overflow: hidden;
}

.center-width {
    margin: 0 auto !important;
    float: none !important;
}

.center-height-parent {
    position: relative !important;
}

.center-height {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
}

.tracking-wider {
    letter-spacing: 1px;
}