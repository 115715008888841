/* mag pop up */

.magnific-popup {

  margin: 0em auto;
  padding: 1em;
  text-align: left;
  /* background: linear-gradient(#333, $black); */
  color: $white;

  .popup-gallery {
    margin: 1em;
    padding: 1em;
    text-align: center;
  }

  .popup-gallery-text {
    text-align: left;
    padding-left: 1em;
    padding-bottom: 2em;

    h1 {
      text-align: center;
      font-size: 15pt;
      color: $grey;

      i {
        margin-right: 1em;
      }
    }

    /* h1 */
  }

  /* end popup gallery text */

  img {
    border-radius: 3px;
    border: 2px solid $white;
    box-shadow: 1px 1px 3px $black;
    margin-top: 3px;
  }

}

/* end mag pop up */

.popup-gallery a img {
  object-fit: none;
  object-position: 0% 50%;
}