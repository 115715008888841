/* fonts */

/*$text: 'Sanchez', serif;*/
$text: 'Roboto Slab',
serif;
$headings: 'Yanone Kaffeesatz',
sans-serif;

/* colors */

/*from eps file provided */
$white:#FFF;
$black:#2d2d2d;

/* from adobe color */
$sand:#FFFAA8;
$brick:#E4995F;
$redBrick:#923A40;
$violet:#B6AEF9;

/* from images */
$purple:#6B3A70;
$off-purple: #523A7D;
$orange:#FA9102;
$blue:#2e80d4;
$off-blue:#5998e7;
$green:#2a450e;
$off-green: #354b0b;
$grey:#7294aa;
$off-grey:#a2b7cb;
$off-white:#f1f6fc;
$red:#b7263a;
$grey-blue:#2a6188;
$beige:#A3A7A1;
$off-beige:#AEB2AD;

/* weight */
$heavy: 700;
$light: 300;