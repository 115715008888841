/* sets the max-width of the container */
@media (min-width: 1200px) {
    .container {
        max-width: 970px;
    }

    .logged-in {
        margin-top: 32px;
    }
}

@media (min-width:800px) {
    .testimonial {
        margin-left: -63px;
        margin-right: -50px;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
    .jumbotron h1 {
        font-size: 20pt;
    }

    .jumbotron p {
        font-size: 12pt;
    }

    .services-snagging-reports {
        .reports {
            /* .text-container {
                bottom: 1em;
                top: auto;
                border-top: 0;
            } */
        }
    }

    .logged-in {
        margin-top: 32px;
    }
}

/* Medium Devices, Desktops // everything below this width will be affected */
@media only screen and (max-width : 992px) {

    .carousel-caption {
        padding: 0;
    }

    .jumbotron h1 {
        font-size: 16pt;
    }

    .jumbotron p {
        font-size: 10pt;
    }


    .services-snagging-reports {

        .snagging,
        .reports,
        .desnagging {
            border-right: none;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .snagging {
            background-image: url('../img/cranes-landscape.png');
            background-repeat: no-repeat;
            background-position: center;
        }

        .desnagging {

            background-image: url('../img/new-homes-portrait.png');

            /* .text-container {
                bottom: auto;
                top: 1em;
                border-bottom: none;
                border-top: 5px solid $white;
            } */
        }
    }

    /* services snagging reports */

}

/* Small Devices, Tablets // everything below this width will be affected */
@media only screen and (max-width : 768px) {
    .logged-in {
        margin-top: 46px;
    }

    .jumbotron h1 {
        font-size: 14pt;
    }

    .jumbotron p .caption-extra {
        display: none;
    }

    .navbar-collapse .collapse {
        margin: 0;
    }

    .navbar-default .navbar-nav {
        margin: 0;
    }

    .navbar-default .navbar-nav ul {
        margin: 0;
        padding: 0;
    }

    .navbar-brand .navbar-brand-headline {
        margin-left: 40px;
        font-size: 13pt;
        top: 6px;
        position: absolute;
    }

    .navbar-default a img {
        width: 40px;
        height: 40px;
        top: 8px;
    }

    .navbar-default .navbar-nav>li>a,
    .navbar-brand {
        height: 50px;
        line-height: 50px;
        padding-top: 0;
    }

    .navbar-default .navbar-nav li {
        background: $white;
        text-align: left;
        border-bottom: 1px solid #eee;
    }

    .navbar-default .navbar-nav li a {
        font-weight: 700;
        border-bottom: none;
        padding-left: 1em;

    }

    .navbar-default .navbar-nav>li>a:hover {
        border-bottom: none;
        background: #eee;
    }

    .navbar-default .navbar-nav>.active>a,
    .navbar-default .navbar-nav>.active>a:focus,
    .navbar-default .navbar-nav>.active>a:hover {
        color: $black;
        border-bottom: none;
    }

    .services-snagging-reports {

        .snagging {
            /* .text-container {
                top: 1em;
                bottom: auto;
                border-top: 5px solid $white;
                border-bottom: 0;
            } */
        }
    }

    .footer .container {
        border-left: 0;
    }




}

/* Extra Small Devices, Phones // everything below this width will be affected*/
@media only screen and (max-width : 480px) {


    .visible {
        display: none;
    }

    .navbar-brand .navbar-brand-headline {
        font-size: 10pt;
    }


    .carousel-control .glyphicon-chevron-left,
    .carousel-control .glyphicon-chevron-right {
        display: none;
    }

    .carousel-indicators li {
        display: none;
    }

    .jumbotron:hover img {
        opacity: 1.0;
    }

    .carousel-caption p {
        display: none;
    }

    .carousel-caption h1 {
        margin-bottom: -15px;
    }

    ul.check-list {
        font-size: 9pt;
        margin: 0;
        padding-left: 1em;
    }

    ul.checklist .glyphicon {
        margin-right: 0;
    }

    .services-snagging-reports {
        .snagging {
            .text-container {
                bottom: 1em;
                top: auto;
                border-top: 0;
                border-bottom: 5px solid $white;
            }
        }
    }

    h4#internal-external-heading {
        margin-bottom: 1.5;
        margin-top: 1em;
        font-weight: 700;
        color: $black;
        font-size: 11pt;
    }

    .snagging-reports .frameWrap {
        display: none;
    }

}

/* query


/* Custom, iPhone Retina // everything below this width will be affected */
@media only screen and (max-width : 320px) {

    .jumbotron h1,
    .jumbotron p {
        display: none;
    }

    .navbar-brand-headline {
        font-size: 9pt;
    }
}