.services-snagging-reports {
 background: $white;
 position: relative;

 .our-services-header {
  background: transparent;
  color: $white;
  padding: 1em 2em;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
 }

 .snagging,
 .reports,
 .desnagging {
  background: $white;
  min-height: 625px;
  position: relative;
  padding-top: 1em;
  padding-bottom: 1em;

  a {
   text-decoration: none;
   color: $white;
  }

  a:hover {
   text-decoration: underline;
   color: $blue;
  }

  .text-container {
   position: absolute;
   min-height: 55%;
   bottom: 1em;
   right: 1em;
   left: 1em;
   color: $white;
   border-left: 5px solid $red;
   padding: 1em;
  }

  .text-container-overlay {
   background: $black;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 100;
   opacity: 0.75;
  }

  .text-container-overlay + div {
   position: relative;
   z-index: 500;
  }

  h1,
  h2,
  h3,
  h4 {
   margin-top: 0;
   font-size: 15pt;
   font-weight: 700;
  }
 }

 /* sections one, two, three */

 .snagging {
  background: url("../img/cranes-portrait.png");
  background-position: center 37%;
  background-size: cover;
  background-repeat: no-repeat;
 }

 /* end our services */

 .desnagging {
  background: url("../img/new-homes-portrait.png");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;

  i {
   color: $white;
  }
 }

 /* end snagging */

 .reports {
  background: url("../img/inspected.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  #site-report {
   color: $white;
  }
 }

 /* end reports */

 .fa {
  margin-right: 0.3em;
  margin-left: 0.3em;
 }
}

/* end services-reports-snagging */

/* easy read is added when .services-snagging is in viewport */

/* .easyRead {
  background: $black;
  color: $white;
  opacity: 0.95;
} */
