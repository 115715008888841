/* about */

.about,
.contact,
.prices,
.example-reports {
  margin-top: 4em;
  min-height: 100vh;

  .page-header {}

  .page-text {
    margin-top: 1em;
  }
}

/* about contact prices */

.about .jumbotron,
.contact .jumbotron,
.prices .jumbotron {
  margin-top: 5em;
  min-height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


.about .jumbotron {
  background-image: url('../img/st-katherines-dock.png');
}

.contact .jumbotron {
  background-image: url('../img/london-skyline-panorama.png');
}

.prices .jumbotron {
  background-image: url('../img/cranes-panorama.png');
}

.about {

  figure.about-page-adam-photo {

    figcaption {
      text-align: center;
      margin: 1em;
    }

  }

}

/* about */



/* prices */

.prices {

  .desnagging {
    padding-top: 4em;
    padding-bottom: 4em;
  }

  span.subtitle {
    text-transform: capitalize;
    font-size: 12pt;
    display: inline-block;
    color: $grey;
  }

  ul.desnagging-list {
    padding-top: 1em;
    padding-bottom: 2em;
  }

  ul.desnagging-list li {
    margin-top: 1em;
    margin-left: -1em;
  }

}

/* prices */

.example-reports {}