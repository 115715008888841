html {
  background: $beige;
}

html,
body {
  min-height: 100%;
  font-family: $text;
  min-width: 320px;
}

body {
  margin-left: auto;
  margin-right: auto;
  color: #333;
  max-width: 1600px;
}

p {
  font-family: $text;
  font-weight: $light;
  line-height: 1.6;
}

.column-match-height {
  min-height: 100px;
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
  height: 25px;
  line-height: 25px;
  font-family: $headings;
  text-transform: uppercase;
}

.jumbotron {

  padding: 0;
  background: $white;
  width: 100%;
  margin-bottom: 0px;

  img {
    width: 100%;
    height: 500px;
  }

  .carousel-inner {
    width: 100%;
    max-height: 500px !important;
  }

}

/* end of jumbotron */


.back-to-top {
  text-align: center;
  font-family: $text;
  text-transform: uppercase;
  margin-bottom: 3em;
  color: #eee;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #eee;
  }

  a span.fa {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 40px;
    margin-bottom: 2px;
  }

  a:hover,
  a:active {
    text-decoration: none;
    color: $black;
  }

  a:hover span.fa {
    color: $black;
  }
}


footer {
  background: #f5f5f5;
  border-top: 1px solid #eee;
  padding-top: 30px;
  padding-bottom: 50px;
  font-size: 10pt;
  position: relative;

  .footer-heading h1 {
    font-family: $text;
    font-size: 12pt;
    color: #ccc;
  }

  .ltd-co {
    margin-top: 3em;
  }

  h1 {
    font-size: 15pt;
    font-weight: 700;
    color: $black;
    letter-spacing: 1px;
  }

  #footer-logo {
    width: 50px;
    height: 50px;
    margin-bottom: 50px;
  }

  .footer-table {
    border-left: 5px solid #eee;
    margin: 0;
    width: 100%;
    font-weight: bold;
    letter-spacing: 1px;

    a {
      /* color: $purple; */
    }

    tr {
      padding: 1em;
    }

    td:first-child {
      text-align: center;
      width: 50px;
      padding: .2em;
    }

    td {
      text-align: left;
      color: $black;
    }

  }

  /* end footer table */

  .employment-opportunities {
    padding: 2em 1em;
    margin: 3em 0;
  }
}

/* end of footer */



.company-no {
  margin-top: 3em;
  font-size: 9pt;
  color: $purple;
}

.well {
  padding: 1em;

  .glyphicon {
    margin-left: .5em;
  }
}

.fourofour {
  height: 300px;

  h1 {
    font-family: $text;
    text-transform: initial;
    text-align: left;
    padding: 3em;
    line-height: 50px;
    height: 50px;
    font-size: 10pt;
  }
}

.trusted-by {
  padding: 2em;
}

.company-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  div {
    flex: 0 0 15%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2em;

    img {
      max-height: 100px;
      margin-bottom: 1em;
    }
  }

  span:not(.logo-visible) {
    display: none;
  }

  span.logo-visible {
    transform: translateY(-100%);
  }
}



@media screen and (max-width:500px) {
  .company-logos {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;


    div {
      max-width: 100%;
    }

    img {
      max-width: 100%;
    }
  }
}

@media screen and (max-width:750px) {
  .company-logos {
    div {
      flex: 1 0 50%;
      text-align: center;
    }
  }
}

@media screen and (max-width:1050px) {
  .company-logos {
    div {
      flex: 1 0 33%;
      text-align: center;
    }
  }
}



.why-use-us {
  background: #f5f5f5;
  height: auto;
  padding-top: 1em;
  padding-bottom: 2em;
  padding-left: 1em;
  padding-right: 1em;
}