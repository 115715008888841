.new-page {
  min-height:1000px;
  background:$white;
  padding:1em;

 .right-sidebar {
    img {
      margin-top:3em;
    }
 }
}

