/* contact page */


.contact-page-form-container {

  .contact-page-form {
    border:1px solid #eee;
    padding:1em;
    box-shadow:2px 2px 3px #ccc;
    border-radius:5px;
    background:#eee;

    .form-group {
      border-bottom:1px dotted white;
      padding-bottom:1em;
    }

    input[name="name"] {
      width:80%;
    }

    input[name="telephone"] {
      width:50%;
    }
  }
}
.form-reply {
  text-align:center;
  margin-top:1em;
}

.contact {
  ul.contact-numbers {
    margin-top:4em;

    li {
      border:none;
      padding-left:0;
      margin-left:0;
      a {
        color:$blue;
        font-weight:bold;
      } /*a*/
    }
  }
} /* contact */
