/* 2020 edit */
.frequently-asked-questions div {
  display: flex;
  justify-content: space-between;

  div {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    p:first-child {
      font-weight: bold;
    }
  }
}


.google-reviews a {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 14pt;
  }

  i {
    margin-right: 1px;
  }

  &:hover {
    text-decoration: none;
  }
}


@media screen and (max-width:975px) {
  .frequently-asked-questions div {
    flex-direction: column;
  }


  .google-reviews a span {
    font-size: 12pt;
  }
}

@media screen and (max-width:600px) {
  .google-reviews a span {
    font-size: 9pt;
  }
}