/* prices */

.prices-container {
 background: $white;
 color: $black;
 min-height: 500px;
}

.prices {
 margin-top: 4em;
}

/* prices */

.prices-heading {
 border-left: 5px solid #eee;
 padding-left: 1em;
 height: 30px;
 line-height: 30px;
}

h1.prices-heading,
h3.prices-heading {
}

.prices-table {
 background: $white;
 color: $black;

 thead {
  color: $black;
 }

 td {
  text-align: center;
 }

 td:first-child {
  width: 50%;
 }

 td:last-child {
  font-weight: 300;
 }

 th {
  text-align: center;
 }
}

.prices-page-main-image {
 background: url("../img/london-skyline-panorama.png");
 min-height: 500px;
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
}
