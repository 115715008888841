.article-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 3em;
}

.article-group article {
    width: 50%;

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2em;
        margin-right: 2em;

        &:hover {
            text-decoration: none;
        }

        p {
            text-align: center;
        }

        img {
            max-height: 75px;
            margin-bottom: 1em;
        }
    }

}

@media screen and (max-width:750px) {
    .article-group article {
        width: 100%;
    }
}