/* modal */

button.modal-button {
  font-size:12pt;
  margin-top:1em;
  background:$blue;
  color:$white;
  box-shadow: 1px 1px 5px $black;
  transition:all .5s;

  i {
    margin-right:.5em;
  }
}

button.modal-button:hover {
  color:$off-white;
  background:$grey;
}
button.modal-button:active {
  background:$grey;
  box-shadow:inset 2px 2px 5px $black;
}

#enquiryModal {
  color:$grey-blue;

  p.modal-title {
      padding-left:.5em;
      text-transform:none;
      font-size:12pt;
  }
  .modal-body {
    font-family:$text;
    font-size:12pt;
  }
  button {
    font-size:12pt;
  }
  button.close {
    padding:5px;
    font-size:20pt;
  }
  input, textarea {
    font-family:$text;
  }
  input#name, input#telephone {
    width:70%;
  }
  input#email {
    width:80%;
  }


  .form-group {
    position:relative;
  }

  .form-control {
    padding-left:40px;
  }

  .form-control + .glyphicon {
    position: absolute;
    color:$beige;
    opacity:0.5;
    left: 11px;
    top: 39px;
  }

  label {
    padding-left:2px;
    font-weight:normal;
  }

}

.modal-button-open {
  text-align:center;
}

