/* opening text */

.opening-text {

  background: $white;
  padding-top: 1em;
  padding-bottom: 1em;

  i.fa {
    margin-right: 1em;
  }

  p {
    margin: 1em 0;
  }

  .opening-text-logo {

    text-align: center;
    margin-bottom: 1em;

    img {
      width: 75px;
      height: 75px;
    }

    @media screen and (max-width:768px) {
      img {
        width:50px;
        height:50px;
      }
    }

    figcaption {
      font-weight: bold;
      text-transform: uppercase;
    }

  }

  /* end logo */

  .contact-details {
    margin-bottom: 2em;
  }

  .contact-details p {
    text-align: center;
    font-weight: bold;
    margin: .3em;

    a {
      color: $purple;
      font-size: 11pt;
    }

    a:hover {
      color: $black;
      text-decoration: none;
    }

  }

}

/* end opening text */